import React from "react";
import "./_social-media.scss";
import facebook from "~static/svg/icons/facebook.svg"
import twitter from "~static/svg/icons/twitter.svg"
import instagram from "~static/svg/icons/instagram.svg"
import linkedin from "~static/svg/icons/linkedin.svg"
import youtube from "~static/svg/icons/youtube.svg"
import pinterest from "~static/svg/icons/pinterest.svg"

const SocialMedia = (
    <ul className="social-media">
        <li>
            <a href="https://www.facebook.com/Hot-Soup-Marketing-Lab-703734539970593/" className="facebook" target="_blank" rel="noreferrer">
                <img src={facebook} alt="Facebook" width="36" height="36"/>
            </a>
        </li>
        <li>
            <a href="https://twitter.com/hotsouplab" className="twitter" target="_blank" rel="noreferrer">
                <img src={twitter} alt="Twitter" width="36" height="36"/>
            </a>
        </li>
        <li>
            <a href="https://www.instagram.com/hotsouplab/" className="instagram" target="_blank" rel="noreferrer">
                <img src={instagram} alt="Instagram" width="36" height="36"/>
            </a>
        </li>
        <li>
            <a href="https://www.linkedin.com/company/68969143/admin/" className="linked-in" target="_blank" rel="noreferrer">
                <img src={linkedin} alt="Linked In" width="36" height="36"/>
            </a>
        </li>
        <li>
            <a href="https://www.youtube.com/channel/UCEDMScLv8y6L5LlSU50W3pQ" className="you-tube" target="_blank" rel="noreferrer">
                <img src={youtube} alt="YouTube" width="36" height="36"/>
            </a>
        </li>
        <li>
            <a href="https://www.pinterest.ca/hotsouplab/_saved/" className="pinterest" target="_blank" rel="noreferrer">
                <img src={pinterest} alt="Pinterest" width="36" height="36"/>
            </a>
        </li>
    </ul>
);

export default SocialMedia;
