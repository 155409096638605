import React, {Component} from "react";
import "./_menu.scss"
import PropTypes from "prop-types";
import {Link} from "gatsby";

class Menu extends Component {

    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            burger: false,
        };
    }

    onClick = () => {
        this.setState({
            burger: !this.state.burger
        });
        document.body.classList.toggle("no-sroll");
    };

    componentWillUnmount() {
        document.body.classList.remove("no-sroll");
    }

    render() {

        const {
            onClick,
            props: { children },
            state: {burger},
        } = this;

        return (
            <nav className={burger ? 'main-nav active' : 'main-nav'}>
               <Link to="https://app.asana.com/-/login" target="_blank" className="btn btn-dark desk-login-btn">Login to your project</Link>
               <div className="burger-menu" onClick={onClick} role="presentation">
                   <span></span>
               </div>
               <ul>
                   {children.map( (child, index) => (
                       <li key={index}>
                           {child}
                       </li>
                   ))}
               </ul>
               <ul className='menuToggle'>
                   <Link to="https://app.asana.com/-/login" target="_blank" className="btn btn-white mob-login-btn">Login to your project</Link>
                   <Link to="/services/products">Product Photos</Link>
                   <Link to="/services/studio">Studio Rental</Link>
                   <Link to="/services/websites">Off-the Shelf Websites</Link>
                   <Link to="/services/content">Content and PR Strategy</Link>
                   <Link to="/services/cmo">Hire a CMO</Link>
                   <Link to="/services/support">Webmaster and Marketing IT Support</Link>
                   <Link to="/services/advertising">Digital Advertising</Link>
               </ul>
           </nav>
        )
    }
}

export default Menu;
