import React from "react"
import PropTypes from "prop-types"

import Header from "./header/header"
import Footer from "./footer/footer"
import "./layout.scss"
import "../../styles/templates.scss"
import "./header/_header.scss"
import "./footer/_footer.scss"

const Layout = ({children, type}) => {

    const wrapper = (type) ? type.toString() : "template-default";

    return (
        <div id="page" className={wrapper}>
            <Header/>
            <main id="main">{children}</main>
            <Footer/>
        </div>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
