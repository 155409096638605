import { Link } from "gatsby"
import React from "react"
import Menu from "../menu/menu";

import logo from "../../../../static/svg/HSL_Logo_White.svg"

const Header = () => (
  <header id="masthead">
      <div className="container">
          <Link to="/" className="logo">
              <img src={logo} alt="Hot Soup Marketing Lab" title="Hot Soup Marketing Lab" />
          </Link>
          <Menu type={'main'}>
              <Link to="/services/products">Product Photos</Link>
              <Link to="/services/studio">Studio Rental</Link>
              <Link to="/services/websites">Websites</Link>
              <Link to="/services/content">Content + PR</Link>
              <Link to="/services/cmo">Hire a CMO</Link>
              <Link to="/services/support">Web + IT Support</Link>
              <Link to="/services/advertising">Digital Advertising</Link>
          </Menu>
      </div>
  </header>
);

export default Header
